import store from './store';
// import router from './router';
import { apolloClient } from './vue-apollo';
import GET_CURRENT_USER from './graphql/Query/GetCurrentUser.gql';
import GET_CUSTOMER from './graphql/Query/GetCustomer.gql';
// import DEFAULT_OTHER_KEYS from './constants/otherMetadata';

function onError(error) {
  console.log('APOLLO ERROR', error);
  // TODO: If unauthorized, go to login w/ reset=true query
}

export const getCustomer = async (providerId, memberId) => {
  try {
    const res = await apolloClient.query({
      query: GET_CUSTOMER,
      variables: {
        memberId,
        providerId,
      },
    });
    const { stripeCustomerId } = res?.data?.getCustomer;
    store.commit('patientPortal/saveUser', { stripeCustomerId });
  } catch (e) {
    console.log('error getting customer: ', e);
  }
};

// eslint-disable-next-line import/prefer-default-export
export async function getAndSaveUser() {
  const { data } = await apolloClient.query({
    query: GET_CURRENT_USER,
    fetchPolicy: 'no-cache',
    onError,
  });
  // TODO: Account for unauthorized errors

  const { endTime, startTime, timeZone } = data.getCurrentUser.metaData;
  const other = JSON.parse(data.getCurrentUser.metaData.other);
  // Save user settings
  const settings = {};

  if (endTime) settings.endTime = endTime;
  if (startTime) settings.startTime = startTime;
  if (timeZone) settings.timezone = timeZone;

  // Save user info
  const info = { ...data.getCurrentUser };
  if (info.primaryRole === 'admin') info.isAdmin = true;

  if (typeof other === 'string') {
    info.metaData.other = JSON.parse(other);
  } else {
    info.metaData.other = other;
  }

  const { id, providerId } = data.getCurrentUser;
  await getCustomer(providerId, id);

  store.commit('patientPortal/saveSettings', settings);
  store.commit('patientPortal/saveUser', info);

  return store.state.user;
}
