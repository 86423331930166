<template>
  <v-navigation-drawer
    v-model="drawerOpen"
    clipped
    class="nav-drawer d-flex flex-column align-between"
    height=""
    hide-overlay
    :permanent="$vuetify.breakpoint.mdAndUp"
    floating
    :fixed="$vuetify.breakpoint.smAndDown"
  >
    <v-list dense class="px-2">
      <v-list-item-group v-model="selectedItem" color="primary">
        <fragment v-for="(item, i) in items" :key="i">
          <v-list-item
            v-if="item.type !== 'expansion'"
            active-class="nav-item--active"
            :input-value="selectedItem === item.value"
            :to="item.to"
            @click="onNavClick(item.value)"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            v-else
            no-action
            :prepend-icon="item.icon"
            class="expandable"
            :value="selectedItem === i || item.subItems.map(({ to }) => to).includes($route.path)"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(item, i) in item.subItems"
              :key="i"
              link
              :to="item.to"
              class="sub-item"
              :value="item.value"
              active-class="nav-item--active"
              @click="onNavClick(item.value)"
            >
              <v-list-item-icon>
                <v-icon small v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item>
          </v-list-group>
        </fragment>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('patientPortal.navigation.logout') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>
<style lang="scss" scoped>
.sub-item {
  padding-left: 20px !important;
  ::v-deep .v-list-item__icon {
    margin-right: 28px !important;
  }
  ::v-deep .v-list-item__title {
    white-space: pre-wrap;
    font-size: 0.7em;
  }
}
.expandable {
  ::v-deep .v-list-group__header {
    padding-right: unset;
  }
  ::v-deep .v-list-group__header__append-icon {
    margin-left: unset !important;
    min-width: 16px !important;
  }
}
</style>
<script>
import { onLogout } from '../../vue-apollo';

export default {
  data() {
    return {
      selectedItem: 'home',
      items: [
        {
          text: this.$t('patientPortal.navigation.home'),
          icon: 'mdi-home',
          to: '/home',
          value: 'home',
        },
        {
          text: this.$t('patientPortal.navigation.appointments'),
          icon: 'mdi-clock',
          to: '/appointments',
          value: 'appointments',
        },

        {
          text: this.$t('patientPortal.navigation.files'),
          icon: 'mdi-file-cabinet',
          to: 'files',
          value: '/files',
        },
        {
          text: this.$t('patientPortal.navigation.invoices'),
          icon: 'mdi-receipt-text',
          to: '/invoices',
          value: 'invoices',
        },
        {
          text: this.$t('patientPortal.navigation.otherServices'),
          icon: 'mdi-medical-bag',
          type: 'expansion',
          value: 'otherServices',
          subItems: [
            {
              text: this.$t('patientPortal.navigation.cannabisAppointment'),
              icon: 'mdi-calendar',
              value: 'cannabisAppointment',
              to: '/book-cannabis',
            },
          ],
        },
        {
          text: this.$t('patientPortal.navigation.settings'),
          icon: 'mdi-cog',
          value: 'settings',
          to: '/settings',
        },
      ],
    };
  },
  props: {
    value: Boolean,
  },
  computed: {
    drawerOpen: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    async logout() {
      const { tenantUrl } = this.$store.getters['patientPortal/currentUser'];
      await this.$store.commit('restoreSession');
      await onLogout();
      this.$router.push({ name: 'Login', params: { tenantUrl } });
    },
    onNavClick(val) {
      this.selectedItem = val;
      window.scrollTo(0, 0);
    },
  },
};
</script>
