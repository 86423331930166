<template>
  <pull-to-refresh
    @refresh="refreshCurrentPage"
    :disabled="$vuetify.breakpoint.mdAndUp"
    class="ma-n4 pa-4"
    style="box-sizing: content-box"
  >
    <div class="notifications--container">
      <content-title class="mb-0 pb-6" :class="{ 'mobile-header': $vuetify.breakpoint.smAndDown }">
        <template v-slot:title>
          <div class="d-flex justify-space-between">
            <div>{{ $t('patientPortal.notifications.title') }} ({{ loading ? '...' : count }})</div>
            <div class="d-flex mt-n2">
              <v-btn icon @click="goBack" :disabled="!page || loading"
                ><v-icon>mdi-chevron-left</v-icon></v-btn
              >
              <v-btn icon @click="goNext" :disabled="loading || isLastPage"
                ><v-icon>mdi-chevron-right</v-icon></v-btn
              >
            </div>
          </div>
        </template>
      </content-title>

      <div v-if="error">{{ $t('patientPortal.notifications.error') }}</div>
      <div v-else-if="loading" class="pt-3">
        <v-skeleton-loader
          type="list-item-two-line"
          min-width="100%"
          class="mb-2"
          v-for="(skeleton, i) in Array.from(Array(4).keys())"
          :key="i"
        />
      </div>
      <v-list v-else-if="!loading && visibleNotifications && visibleNotifications.length">
        <v-slide-x-transition group>
          <notification
            v-for="item in visibleNotifications"
            :key="item.id"
            :notification="item"
            :dismissNotification="() => dismissNotification(item.id)"
          />
        </v-slide-x-transition>
      </v-list>
      <div v-else-if="!notifications || !notifications.length" class="text-sm">
        <v-list-item-subtitle>{{ $t('patientPortal.notifications.none') }}</v-list-item-subtitle>
      </div>
      <div v-if="end" class="text-sm text-center grey--text text--darken-3">
        <v-list-item-subtitle class="end">{{
          $t('patientPortal.notifications.end')
        }}</v-list-item-subtitle>
      </div>
    </div>
  </pull-to-refresh>
</template>
<style lang="scss" scoped>
::v-deep .v-list-item__subtitle {
  &.end {
    font-size: 0.8em !important;
  }
}
::v-deep .mobile-header {
  position: relative;
  background-color: white;
  z-index: 11;
}
</style>
<script>
import debounce from 'lodash.debounce';
import ContentTitle from '../ContentTitle.vue';
import UPDATE_NOTFICATION from '../../graphql/Mutation/UpdateNotification.gql';

const PAGINATION_SIZE = 4;

export default {
  data() {
    return {
      count: 0,
      debounceGetMore: null,
      end: false,
      error: false,
      loading: false,
      notifications: [],
      page: 0,

      selectedItem: null,
    };
  },
  props: {
    visible: Boolean,
  },
  components: {
    ContentTitle,
    Notification: () => import('./Notification.vue'),
    PullToRefresh: () => import('./PullToRefresh.vue'),
  },
  created() {
    this.debounceGetMore = debounce(
      ({ next, prev }) => {
        this.getNotifications({ next, prev });
      },
      100,
      { trailing: true }
    );
  },
  computed: {
    providerId() {
      return this.$store.getters['patientPortal/providerId'];
    },
    isLastPage() {
      return (this.page + 1) * PAGINATION_SIZE >= this.count;
    },
    userId() {
      const { id: userId } = this.$store.getters['patientPortal/currentUser'];
      return userId;
    },
    visibleNotifications() {
      const startIndex = this.page * PAGINATION_SIZE;
      return [...this.notifications]
        .filter(({ dismissed }) => !dismissed)
        .slice(startIndex, startIndex + PAGINATION_SIZE);
    },
  },
  methods: {
    localUpdateNotification(bool, id) {
      const index = this.visibleNotifications.findIndex(
        ({ id: notificationId }) => notificationId === id
      );
      const newNotifications = [...this.visibleNotifications];
      newNotifications.splice(index, 1, {
        ...newNotifications[index],
        dismissed: bool,
      });
      this.$set(this, 'notifications', newNotifications);
    },
    // Allow for dismissing if we want that down the road
    async dismissNotification(id) {
      this.localUpdateNotification(true, id);
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_NOTFICATION,
          variables: {
            providerId: this.providerId,
            userRole: 'member',
            userId: this.userId,
            updates: { dismissed: true },
            notificationId: id,
          },
        });
        this.refreshCurrentPage(false);
      } catch {
        this.localUpdateNotification(false, id);
      }
    },
    // QUERY notification IDs to get a total count, for pagination
    async getNotificationCount() {
      try {
        const { data } = await this.$apollo.query({
          query: await this.$store.getters.getQuery('GetNotificationCount'),
          variables: {
            providerId: this.providerId,
            userRole: 'member',
            userId: this.userId,
          },
          fetchPolicy: 'no-cache',
        });
        const { getAllPlatformUserNotifications: notifications = [] } =
          data?.CommunicationService?.Platform || {};
        this.count = notifications.length;
      } catch {
        this.count = 0;
      }
    },
    async getNotifications({ next = null, prev = null, showLoading = true }) {
      if (this.loading) return;
      if (showLoading) this.loading = true;
      this.error = false;
      const pagination = {
        limit: PAGINATION_SIZE,
      };

      if (next) {
        pagination.next = next;
      } else if (prev) {
        pagination.prev = prev;
      }
      try {
        const { data } = await this.$apollo.query({
          query: await this.$store.getters.getQuery('GetNotifications'),
          variables: {
            providerId: this.providerId,
            userRole: 'member',
            userId: this.userId,
            filter: {
              includeDismissed: false,
            },
            pagination,
          },
          fetchPolicy: 'no-cache',
        });
        const { getAllPlatformUserNotifications: notifications = [] } =
          data?.CommunicationService?.Platform || {};
        if (this.page === 0) {
          this.$set(this, 'notifications', [...notifications]);
        } else {
          this.$set(this, 'notifications', [...this.notifications, ...notifications]);
        }
      } catch {
        this.error = true;
      }
      this.loading = false;
    },
    goBack() {
      if (this.page === 0) return;
      this.page -= 1;
    },
    goNext() {
      if (this.isLastPage) return;
      this.page += 1;
      this.debounceGetMore({ next: this.notifications[this.notifications.length - 1].id });
    },
    refreshCurrentPage(showLoading = true) {
      this.getNotificationCount();
      if (this.page === 0) {
        this.getNotifications({ next: null, prev: null, showLoading });
      } else {
        this.getNotifications({
          next: this.notifications[this.page * PAGINATION_SIZE - 1].id,
          showLoading,
        });
      }
    },
  },
  mounted() {
    this.refreshCurrentPage();
  },
  watch: {
    visibleNotifications(val) {
      this.$emit('isNotifications', !!val?.length);
    },
  },
};
</script>
