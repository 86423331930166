<template>
  <div id="patient-portal">
    <v-fade-transition>
      <div
        class="global-loading--wrap d-flex justify-center align-center flex-column mt-n2"
        v-if="loading"
      >
        <div :style="{ width: $vuetify.breakpoint.xsOnly ? '150px' : '200px' }">
          <logo-image height="fit-content" />
          <v-progress-linear
            indeterminate
            color="primary"
            striped
            rounded
            :height="$vuetify.breakpoint.xsOnly ? 8 : 10"
            class="mt-2 mt-md-4"
          />
        </div>
      </div>
    </v-fade-transition>
    <token-handler />
    <v-app-bar
      clipped-left
      style="z-index: 2"
      class="patient-portal__header"
      :prominent="$vuetify.breakpoint.mdAndUp"
      elevation="0"
      fixed
    >
      <v-btn icon v-if="$vuetify.breakpoint.smAndDown" @click="toggleOpen"
        ><v-icon>mdi-menu</v-icon></v-btn
      >
      <div class="logo-image__wrap clickable" v-if="$vuetify.breakpoint.smAndDown" @click="goHome">
        <logo-image height="100%" :maxWidth="$vuetify.breakpoint.xsOnly ? '80px' : '150px'" />
      </div>

      <v-menu
        :close-on-content-click="false"
        v-model="mobileNotifications"
        v-if="$vuetify.breakpoint.smAndDown"
        content-class="notification-menu"
        transition="slide-x-reverse-transition"
        eager
      >
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            color="error"
            overlap
            :value="showNotificationBadge"
            dot
            offset-y="15"
            offset-x="15"
            bottom
          >
            <v-btn icon v-bind="attrs" v-on="on" :color="mobileNotifications ? 'primary' : ''"
              ><v-icon>mdi-bell</v-icon></v-btn
            >
          </v-badge>
        </template>
        <div class="notification-container--floating">
          <notifications @isNotifications="setNotificationsBadge" :visible="mobileNotifications" />
        </div>
      </v-menu>
      <div class="logo-image__wrap clickable" v-else @click="goHome">
        <logo-image height="100%" maxWidth="200px" />
      </div>
    </v-app-bar>
    <v-main class="patient-portal__wrap" v-if="!loading">
      <drawer v-model="open" />
      <div class="page-wrap">
        <div class="page-wrap--inner pa-6 pa-md-6">
          <v-slide-x-transition mode="out-in">
            <router-view />
          </v-slide-x-transition>
        </div>
        <div v-if="$vuetify.breakpoint.mdAndUp" class="page-wrap--side">
          <notifications />
        </div>
      </div>
    </v-main>
  </div>
</template>
<style lang="scss">
@import '../../styles/patient-portal.scss';
.global-loading--wrap {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: white;
  z-index: 1000;
}
</style>
<script>
import LogoImage from '../LogoImage.vue';
import Drawer from './Drawer.vue';
import Notifications from './Notifications.vue';
import TokenHandler from './TokenHandler.vue';
import { getAndSaveUser } from '../../requests';
import { AUTH_TOKEN } from '../../constants/storage';

export default {
  data() {
    return {
      loading: false,
      open: false,
      mobileNotifications: false,
      showNotificationBadge: false,
    };
  },
  components: {
    Drawer,
    LogoImage,
    Notifications,
    TokenHandler,
  },
  methods: {
    goHome() {
      if (this.$route.fullPath === '/home') return;
      this.$router.push({ path: '/home' });
    },
    setNotificationsBadge(bool) {
      this.showNotificationBadge = bool;
    },
    toggleOpen() {
      this.open = !this.open;
    },
    toggleMobileNotifications() {
      this.mobileNotifications = !this.mobileNotifications;
    },
    async verifyUserData() {
      if (!localStorage.getItem(AUTH_TOKEN)) this.$router.push({ path: '/login' });
      const { id: userId } = this.$store.getters['patientPortal/currentUser'];
      if (userId) return;
      this.loading = true;
      await getAndSaveUser();
      this.loading = false;
    },
  },
  created() {
    this.verifyUserData();
  },
};
</script>
